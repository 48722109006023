import * as React from "react"

import * as s from "../styles/Layout.module.scss"
import { Link } from "gatsby"
import { swapTheme, Themes } from "../utils/themes"
import { useContext, useState } from "react"
import { ThemeContext } from "./ThemeProvider"

const Layout = ({
  pageTitle = "",
  contentWidth = null,
  children,
  pp = null,
}) => {
  const { theme, updateTheme } = useContext(ThemeContext)

  // Coupled to the input checked attribute
  const [navShown, setNavShown] = useState(false)

  return (
    <div
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
      onClick={() => {
        setNavShown(false)
      }}
    >
      <title>{pageTitle ? `MartLugt - ${pageTitle}` : "MartLugt"}</title>
      <header
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <div
          className={s.navbar}
          style={{
            boxShadow: navShown
              ? "0 2px 8px rgba(0, 0, 0, 0.25)"
              : "0 4px 16px rgba(0, 0, 0, 0.25)",
            transition: navShown ? "box-shadow 0s" : "box-shadow 0s 0.2s",
          }}
        >
          <div className={s.navcontainer}>
            <input
              className={s.sideNav}
              type="checkbox"
              id="sideMenu"
              onChange={e => setNavShown(e.target.checked)}
              checked={navShown}
            />
            <label className={s.hamb} htmlFor="sideMenu">
              <span className={s.hambLine}></span>
            </label>
            <Link to={"/wip"}>&#123; MartLugt &#125;</Link>
            <nav>
              <Link to={"/blog/"}>Blog</Link>
              <Link to={"/portfolio"}>Portfolio</Link>
              <Link to={"/wip"}>About</Link>
            </nav>
            <span
              className="material-icons-round"
              onClick={() => swapTheme(updateTheme)}
              style={{ opacity: theme ? 1 : 0 }}
            >
              {theme == Themes.DARK ? "dark_mode" : "light_mode"}
            </span>
          </div>
        </div>
      </header>
      <div className={s.navBarSpacer}></div>

      <main style={{ minWidth: contentWidth }}>{children}</main>

      <footer>
        <div className={s.bar}>
          <div className={s.container}>
            <div>
              {pp && (
                <a
                  className={s.onlyfans}
                  onClick={() => {
                    if (pp) pp(true)
                  }}
                />
              )}
              <a className={s.github} href="https://github.com/MartLugt" />
              <a
                className={s.linkedin}
                href="https://www.linkedin.com/in/martlugt/"
              />
              <a
                className="material-icons-round"
                href="mailto:mart@martlugt.nl"
              >
                email
              </a>
              <a
                className="material-icons-round"
                href="https://buymeacoffee.com/martlugt"
              >
                coffee
              </a>
            </div>
            <small>© {new Date().getFullYear()} Mart van der Lugt</small>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
