export const lut = {
  c: "#2196f3",
  "c-header": "#2196f3",
  pio: "#43a122",
  mindfuck: "#7A5901",
  "coens-zusje": "#fffcab",
  sh: "#89e051",
  dockerfile: "#c678dd",
}

// half reference: https://github.com/ozh/github-colors/blob/master/colors.json
