import React, { useContext, useEffect, useState } from "react"

import SyntaxHighlighter from "react-syntax-highlighter"
import {
  atomOneDark,
  atomOneLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs"

import * as s from "../styles/CodeBlock.module.scss"
import { lut } from "../styles/extension_colors"
import {
  CodeThemes,
  getTextColor,
  setTheme,
  Themes,
  WhatTheme,
} from "../utils/themes"
import { ThemeContext } from "./ThemeProvider"

const getExtensionColor = (
  extension: string
): { backgroundColor: string; color: string } | null => {
  if (!(extension.toLowerCase() in lut)) return null
  let bgColor = lut[extension.toLowerCase()]

  let text = getTextColor(
    bgColor,
    "rgba(255, 255, 255, 0.87)",
    "rgba(0, 0, 0, 0.87)"
  )

  return { backgroundColor: bgColor, color: text }
}

const CodeBlock = ({
  className,
  filename,
  extension,
  lineNumbers,
  children,
}) => {
  const { theme, codeTheme, updateCodeTheme } = useContext(ThemeContext)
  const [popup, showPopup] = useState(undefined)
  const [failedPopup, showFailedPopup] = useState(undefined)

  let lang = className ? className.replace(/^(language-)/, "") : "text"
  children = children.slice(0, -1)

  const copyContent = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(children)
      showPopup(true)
      const tm = setTimeout(() => {
        showPopup(false)
      }, 1000)
      return () => clearTimeout(tm)
    } else {
      showFailedPopup(true)
      const tm = setTimeout(() => {
        showFailedPopup(false)
      }, 2000)
      return () => clearTimeout(tm)
    }
  }

  const swapTheme = () => {
    if (codeTheme === CodeThemes.DARK) {
      updateCodeTheme(CodeThemes.LIGHT)
      setTheme(WhatTheme.CODE_THEME, CodeThemes.LIGHT)
    } else if (codeTheme === CodeThemes.LIGHT) {
      updateCodeTheme(CodeThemes.DARK)
      setTheme(WhatTheme.CODE_THEME, CodeThemes.DARK)
    }
  }

  return (
    <div
      className={`codeBlock ${s.card} ${codeTheme}`}
      style={{ opacity: codeTheme ? 1 : 0 }}
    >
      {filename ? <></> : <div style={{ height: "1rem" }}></div>}
      <div className={`${s.topBar} ${filename ? "" : s.floating}`}>
        {filename ? (
          <div className={s.filename}>
            <span>{filename}</span>
          </div>
        ) : (
          <></>
        )}
        <div className={s.icon} onClick={copyContent}>
          <span className="material-icons-round">content_copy</span>
        </div>
        <div className={s.icon} onClick={swapTheme}>
          <span className="material-icons-round">
            {codeTheme === CodeThemes.DARK ? "dark_mode" : "light_mode"}
          </span>
        </div>
        {extension ? (
          <div className={s.ext} style={getExtensionColor(extension)}>
            <span>{extension}</span>
          </div>
        ) : (
          <div style={{ marginLeft: "0.5rem" }}></div>
        )}
      </div>

      <div
        className={`${s.code} ${lineNumbers ? s.codeLineNumbers : ""}`}
        style={{ opacity: theme ? 1 : 0 }}
      >
        <SyntaxHighlighter
          language={lang}
          style={codeTheme === CodeThemes.DARK ? atomOneDark : atomOneLight}
          wrapLongLines
          showLineNumbers={lineNumbers}
        >
          {children}
        </SyntaxHighlighter>
        <div
          className={`${s.popup} ${
            popup ? s.show : popup === undefined ? "" : s.hide
          }`}
          style={{
            marginTop: filename
              ? 0
              : "calc(0.75rem + 0.375rem + 1rem + 0.375rem)",
          }}
          onClick={() => showPopup(false)}
        >
          <span className="material-icons-round">done</span>
          <span>Code copied</span>
        </div>
        <div
          className={`${s.popup} ${s.failed} ${
            failedPopup ? s.show : failedPopup === undefined ? "" : s.hide
          }`}
          style={{
            marginTop: filename
              ? 0
              : "calc(0.75rem + 0.375rem + 1rem + 0.375rem)",
          }}
          onClick={() => showFailedPopup(false)}
        >
          <span className="material-icons-round">close</span>
          <span>Copying failed</span>
        </div>
      </div>
    </div>
  )
}

export default CodeBlock
