import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import React, { useState } from "react"

import * as s from "../styles/customimage.module.scss"

type Props = {
  image: ImageDataLike
  alt: string
  subtext?: string
  style?: React.CSSProperties
  credit?: string
}

const CustomImage = ({ image, alt, subtext, style, credit }: Props) => {
  let loadedImage = getImage(image)

  const [showBigImage, setShowBigImage] = useState(false)

  return (
    <>
      <div className={s.container}>
        <GatsbyImage
          className={subtext ? s.imageWithSubtext : s.imageFull}
          image={loadedImage}
          alt={alt}
          style={style}
          onClick={() => setShowBigImage(true)}
        />
        <div className={s.creditContainer}>{credit}</div>
        {subtext && (
          <div className={s.subtext}>
            <span>{subtext}</span>
          </div>
        )}
      </div>
      {showBigImage && (
        <div
          className={s.bigImageContainer}
          onClick={e => {
            setShowBigImage(false)
            e.stopPropagation()
          }}
        >
          <div className={s.container} onClick={e => e.stopPropagation()}>
            <GatsbyImage
              className={subtext ? s.imageWithSubtext : s.imageFull}
              image={loadedImage}
              alt={alt}
              style={style}
            />
            <div className={s.creditContainer}>{credit}</div>
            {subtext && (
              <div className={s.subtext}>
                <span>{subtext}</span>
              </div>
            )}
            <span
              className="material-icons-round"
              onClick={() => setShowBigImage(false)}
            >
              close
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomImage
