import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image"
import React from "react"
import { Link } from "gatsby"

import * as s from "../styles/biglink.module.scss"

type Props = {
  image: ImageDataLike
  alt: string
  title: string
  cta: string
  link: string
}

const BigLink = ({ image, alt, title, cta, link }: Props) => {
  let loadedImage = getImage(image)
  return (
    <>
      <Link to={link} className={s.card}>
        <GatsbyImage className={s.images} image={loadedImage} alt={alt} />
        <div className={s.text}>
          <span>{cta}</span>
          <span>{title}</span>
        </div>
        <div className={s.icon}>
          <span className="material-icons-round">arrow_forward_ios</span>
        </div>
      </Link>
    </>
  )
}

export default BigLink
