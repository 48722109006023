import { Link } from "gatsby"
import React, { useEffect } from "react"

import * as s from "../styles/toc.module.scss"
import { slugify } from "../utils/funcs"

const ToC = ({ headings, add_intro }) => {
  return (
    <div
      className={s.container}
      style={{ visibility: headings.length >= 2 ? "visible" : "hidden" }}
    >
      <div className={s.content}>
        <h3>Table of contents</h3>
        {add_intro && (
          <Link
            to={`#${slugify("intro")}`}
            key={"intro"}
            style={{ marginLeft: "0" }}
          >
            Introduction
          </Link>
        )}
        {headings
          .filter(h => h.depth !== 1)
          .map(h => (
            <Link
              to={`#${slugify(h.value)}`}
              key={h.depth + "-" + h.value}
              style={{ marginLeft: `${(h.depth - 2) * 1.4}rem` }}
            >
              {h.value}
            </Link>
          ))}
      </div>
    </div>
  )
}

export default ToC
