// extracted by mini-css-extract-plugin
export var card = "CodeBlock-module--card--f1830";
export var code = "CodeBlock-module--code--2b5eb";
export var codeLineNumbers = "CodeBlock-module--codeLineNumbers--b1240";
export var topBar = "CodeBlock-module--topBar--b5035";
export var floating = "CodeBlock-module--floating--3c557";
export var filename = "CodeBlock-module--filename--f9e76";
export var ext = "CodeBlock-module--ext--1791d";
export var icon = "CodeBlock-module--icon--56d5b";
export var popupContainer = "CodeBlock-module--popupContainer--4ba2b";
export var popup = "CodeBlock-module--popup--a8724";
export var failed = "CodeBlock-module--failed--099b6";
export var hide = "CodeBlock-module--hide--7636c";
export var fadeOut = "CodeBlock-module--fadeOut--759e5";
export var show = "CodeBlock-module--show--f4266";
export var fadeIn = "CodeBlock-module--fadeIn--3a533";