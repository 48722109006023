import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import React, { useEffect } from "react"

import Layout from "../../components/Layout"

import * as s from "../../styles/blogpost.module.scss"
import PostFooter from "../../components/PostFooter"
import BigLink from "../../components/BigLink"
import CodeBlock from "../../components/CodeBlock"
import ToC from "../../components/ToC"
import { slugify } from "../../utils/funcs"
import CustomImage from "../../components/CustomImage"

const Anchor = ({ text, topMargin = 3, show = true }) => {
  return (
    <div style={{ display: "inline-block" }}>
      <span className={`material-icons-round ${s.iconanchor}`}>link</span>
      <div
        id={`${slugify(text)}`}
        className={s.anchor}
        style={{
          bottom: `calc(6rem + ${topMargin}rem)`,
        }}
      ></div>
    </div>
  )
}

export const Audio = ({ source }: { source: { publicURL: string }[] }) => {
  console.log(source)
  return (
    <>
      <audio
        controls
        preload=""
        style={{ width: "100%", borderRadius: "20px" }}
      >
        {source.map(s => (
          <source src={s.publicURL} />
        ))}
      </audio>
    </>
  )
}

const Post = ({ data }) => {
  const isProduction = process.env.NODE_ENV === "production"

  useEffect(() => {
    if (isProduction && !data.mdx.frontmatter.published) {
      navigate("/wip")
    }
  })

  const loadedImage = getImage(data.mdx.frontmatter.hero_image)
  const components = {
    PostFooter,
    BigLink,
    Audio,
    pre: ({ children }) => <>{children}</>,
    code: CodeBlock,
    h2: ({ children }) => (
      <Link to={`#${slugify(children)}`}>
        <h2 className={s.hoveranchor}>
          {children}
          <Anchor text={children} />
        </h2>
      </Link>
    ),
    h3: ({ children }) => (
      <Link to={`#${slugify(children)}`}>
        <h3 className={s.hoveranchor}>
          {children}
          <Anchor text={children} topMargin={2.5} />
        </h3>
      </Link>
    ),
    h4: ({ children }) => (
      <Link to={`#${slugify(children)}`}>
        <h4 className={s.hoveranchor}>
          {children}
          <Anchor text={children} topMargin={2} />
        </h4>
      </Link>
    ),
    Image: CustomImage,
  }

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div className={s.container}>
        {isProduction && !data.mdx.frontmatter.published ? (
          <div className={s.text}>
            {/* <p>
              This page is still in development!{" "}
              <a href="mailto:mart@martlugt.nl">
                Send me a message to tell me to hurry up!
              </a>
            </p> */}
          </div>
        ) : (
          <>
            <div className={s.heroImageContainer}>
              <GatsbyImage
                className={s.heroImage}
                image={loadedImage}
                alt={data.mdx.frontmatter.hero_image_alt}
                loading="eager"
              />
              {data.mdx.frontmatter.hero_image_credit && (
                <div className={s.credit}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.mdx.frontmatter.hero_image_credit,
                    }}
                  ></span>
                </div>
              )}
            </div>
            <div className={s.text}>
              <h1>{data.mdx.frontmatter.title}</h1>
              {!data.mdx.frontmatter.hide_toc && (
                <ToC
                  headings={data.mdx.headings}
                  add_intro={data.mdx.frontmatter.intro_toc}
                ></ToC>
              )}
              <div
                id={`${slugify("intro")}`}
                className={s.anchor}
                style={{
                  bottom: `calc(4rem + ${3}rem)`,
                }}
              ></div>
              <MDXProvider components={components}>
                <MDXRenderer
                  images={data.mdx.frontmatter.images}
                  audio={data.mdx.frontmatter.audio}
                >
                  {data.mdx.body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        published
        title
        date(formatString: "MMMM D, YYYY")
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_alt
        hero_image_credit
        images {
          childImageSharp {
            gatsbyImageData
          }
        }
        audio {
          publicURL
        }
        hide_toc
        intro_toc
      }
      body
      headings {
        depth
        value
      }
    }
  }
`

export default Post
